<template>
  <div :style="{height: pageH + 'px'}">
    <div
      class="assessment-logo"
      style="padding: 20px"
    >
      <img src="../../assets/images/logo/picaas-b.png" alt="">
    </div>

    <div
      v-loading="loading"
      class="assessment-login-wrap"
    >
      <h1 class="text-center mb20">PICaaS个人信息合规平台</h1>
      <h1 class="text-center mb20">
        新系统授权页面
      </h1>
      <b-card>
        <el-form
          ref="form"
          label-width="80px"
          :model="form"
        >
          <el-form-item label="机器码：">
            <div>{{ machineCode }}</div>
          </el-form-item>
          <el-form-item
            prop="LicenseCode"
            label="授权码："
            :rules="[ { required: true, message: '授权码不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.LicenseCode"
              placeholder="请输入密匙"
              type="textarea"
            />
          </el-form-item>
          <el-form-item
            label="上传文件"
            prop="fileName"
            :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
          >
            <input
              id="uploadFileInput"
              accept=".xls,.xlsx"
              type="file"
              style="display: none"
              @change="uploadFileInputChange"
            >
            <el-input
              v-model="form.fileName"
              disabled
              placeholder="请上传文件"
              class="upload-file-input"
            >
              <template slot="append">
                <div
                  style="cursor: pointer;"
                  @click="uploadFileClick"
                >
                  选择文件
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button
          style="width: 100%"
          type="primary"
          class="mt20"
          @click="toLogin"
        >
          提 交
        </el-button>
      </b-card>
    </div>

  </div>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetMachineCode, CommitLicenseCode } from '@/api/system/system'

export default {
  name: 'AssementLogin',
  data() {
    return {
      form: {
        LicenseCode: '',
        accessPassword: '',
      },
      loading: false,
      machineCode: '',
    }
  },
  created() {
    this.getMachineCode()
  },
  methods: {
    getMachineCode() {
      GetMachineCode().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.machineCode = resData.data
        } else {
          error(resData.msg)
        }
      })
    },
    toLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          CommitLicenseCode(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$router.push({
                path: '/login',
              })
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    uploadFileClick() {
      document.getElementById('uploadFileInput').click()
    },
    uploadFileInputChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.fileName = fileVal[0].name
    },
  },
}
</script>

<style scoped>
  .assessment-login-wrap{
    width: 600px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    margin-top: -185px;
  }
</style>
